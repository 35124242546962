<template>
  <v-card
    class="my-card mt-12"
    color="primaryColor">
    <!-- profile -->
    <div class="profile-details-card white">
      <!-- user-details -->
      <div class="d-flex justify-space-between align-center mb-4">
        <!-- user-avatar -->
        <div class="d-flex justify-between align-center">
          <v-avatar
            width="120px"
            height="120px"
            style="font-size: 52px"
            class="primaryColor lighten-5 mr-4 primaryColor--text text--darken-3 font-weight-medium avatar text-uppercase"
            size="">
            {{ userFSDocument?.name?.charAt(0) ?? 'L' }}
          </v-avatar>

          <div class="user-details">
            <!-- name -->
            <div class="user-name font-weight-medium">
              <v-skeleton-loader
                v-if="isLoadingUserDocument"
                type="text"></v-skeleton-loader>
              <span
                class="user-name"
                v-else
                >{{ userFSDocument?.name }}</span
              >
            </div>
            <div style="display: flex">
              <!-- organization-name -->
              <v-skeleton-loader
                v-if="isFetchingOrganization"
                type="text"></v-skeleton-loader>
              <div
                v-else
                class="user-organization">
                {{ organizationName }}
              </div>
              <!-- monthly-consumed-time -->
              <v-skeleton-loader
                v-if="isLoadingProfileStats"
                type="text"></v-skeleton-loader>
              <div
                class="month-container"
                v-else>
                <template>
                  <v-icon
                    size="8"
                    color="#3DDED6"
                    >mdi-circle</v-icon
                  >
                </template>
                <span class="font"> {{ monthlyConsumedTimeFormatted }}</span>
                <h6 class="month">this month</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- message-loading -->
      <div v-if="isLoadingStreak">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </div>

      <!-- message -->
      <v-card
        width="70%"
        height="105px"
        v-if="!isLoadingStreak && isGreetingVisible"
        class="mb-4 pa-4 elevation-0 message-card"
        color="offWhite">
        <div class="message-card-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="154" height="126" fill="none" viewBox="0 0 154 126" v-bind:svg-inline="''" v-bind:class="'mb-0'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path stroke="url(#a)" stroke-linecap="round" stroke-linejoin="round" stroke-opacity=".12" stroke-width="13.917" d="M59.146 85.896A17.396 17.396 0 0076.542 68.5c0-9.602-3.48-13.917-6.958-20.875-7.46-14.912-1.559-28.21 13.916-41.75 3.48 17.396 13.917 34.096 27.834 45.23 13.916 11.133 20.875 24.353 20.875 38.27a48.707 48.707 0 01-97.417 0c0-8.023 3.013-15.962 6.958-20.875a17.396 17.396 0 0017.396 17.396v0z"/><defs><linearGradient id="a" x1="34.792" x2="132.209" y1="71.979" y2="71.979" gradientUnits="userSpaceOnUse"><stop stop-color="var(--v-primaryColor-base)"/><stop offset="1" stop-color="var(--v-secondaryColor-base)"/></linearGradient></defs></svg>
        </div>
        <div class="message-header d-flex justify-space-between align-center">
          <div class="message-greetings font-weight-medium primaryColor--text">
            {{ streakMessageHeader }}
          </div>
          <v-btn
            class="px-0 mx-0 text-capitalize"
            color="brandGrey2"
            text
            @click="hideGreeting">
            Hide
          </v-btn>
        </div>

        <div class="message-content">
          {{ streakMessageContent.firstPart }}
          <span class="font-weight-medium">{{ streakOfThisWeek }} day streak</span
          ><span v-if="streakMessageContent.space">&nbsp;</span>{{ streakMessageContent.secondPart }}
        </div>
      </v-card>

      <!-- quick-access -->
      <div class="wraper stats-container">
        <!-- statistics -->
        <div class="tabs tab-container">
          <v-tabs
            center-active
            v-model="selectedTab"
            fixed-tabs
            class=""
            color="primaryColor">
            <v-tab class="text--black font-weight-medium"> THIS WEEK </v-tab>
            <v-tab class="text--black font-weight-medium"> LAST WEEK </v-tab>

            <v-tabs-items v-model="selectedTab">
              <v-tab-item>
                <WeekStats
                  v-if="!isLoadingProfileStats && !isLoadingStreak"
                  id="this-week"
                  :consumedTimeByDay="consumedTimeThisWeek"
                  :streak="streakOfThisWeek"
                  :exercicesNumber="totalCoursesThisWeek" />

                <div
                  v-else
                  class="text-center mt-16">
                  <v-progress-circular
                    color="primaryColor"
                    indeterminate></v-progress-circular>
                </div>
              </v-tab-item>
              <v-tab-item>
                <WeekStats
                  v-if="!isLoadingProfileStats && !isLoadingStreak"
                  id="last-week"
                  :consumedTimeByDay="consumedTimeLastWeek"
                  :streak="streakOfLastWeek"
                  :exercicesNumber="totalCoursesLastWeek" />

                <div
                  v-else
                  class="text-center mt-16">
                  <v-progress-circular
                    color="primaryColor"
                    indeterminate></v-progress-circular>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </div>
        <div class="btn-container ml-auto">
          <div class="fav">
            <!-- favourites-CTA -->
            <v-btn
              width="100%"
              height="56"
              elevation="0"
              @click="navigateToRoute('Favourites')"
              class="quick-access-button primaryColor lighten-3 overflow-hidden py-7 pl-0 rounded-lg"
              :class="{
                'quick-access-light-text': organizationTheme?.primaryColor,
                'quick-access-dark-text': !organizationTheme?.primaryColor,
              }">
              <div class="quick-access-icon pa-4">
                <v-icon>mdi-heart-outline</v-icon>
              </div>
              <div class="quick-access-text">FAVOURITES</div>
              <v-icon class="his-icon">mdi-arrow-right</v-icon>
            </v-btn>
          </div>
          <div class="history">
            <!-- history-CTA -->
            <v-btn
              width="100%"
              height="56"
              elevation="0"
              @click="navigateToRoute('History')"
              class="quick-access-button primaryColor lighten-3 overflow-hidden py-7 pl-0 rounded-lg"
              :class="{
                'quick-access-light-text': organizationTheme?.primaryColor,
                'quick-access-dark-text': !organizationTheme?.primaryColor,
              }">
              <div class="quick-access-icon pa-4">
                <v-icon>mdi-update</v-icon>
              </div>
              <div class="quick-access-text">HISTORY</div>
              <v-icon class="his-icon">mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
  import ProfileMixin from '@/views/profile/profile/Profile.mixin.vue';
  export default {
    mixins: [ProfileMixin],
  };
</script>

<style scoped>
  .tab-container {
    display: flex;
    width: 40%;
    height: 70%;
  }
  .stats-container {
    display: flex;
    width: 70%;
  }
  .icon-wraper {
    width: 50%;
  }

  .oorganization-container {
    display: flex;
    flex-direction: row;
    margin-top: 12%;
  }

  .history {
    display: flex;
    width: 100%;
    height: 20%;
    margin-top: 3%;
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    width: 25%;
    height: 100%;
  }
  .user-details {
    color: var(--v-primaryColor-base);
  }
  .month-container {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }
  .user-organization {
    font-family: Montserrat;
    font-style: Regular;
    font-size: 14px;
    line-height: 17px;
    line-height: 100%;
    text-align: Left;
    vertical-align: Top;
    letter-spacing: 16%;
    color: #0d2f4e;
    font-weight: 400;
    margin-top: 8px;
    margin-right: 16px;
  }
  .month {
    font-family: Montserrat;
    font-style: Regular;
    font-size: 14px;
    line-height: 17px;
    line-height: 100%;
    text-align: Left;
    vertical-align: Top;
    letter-spacing: 16%;
    color: #0d2f4e;
    font-weight: 400;
    margin-left: 2px;
  }
  .font {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 17px;
    line-height: 100%;
    text-align: Left;
    vertical-align: Center;
    letter-spacing: 16%;
    color: #0d2f4e;
    fill: Solid;
    font-weight: 600;
    margin-left: 16px;
  }
  .user-name {
    font-family: Montserrat;
    font-size: 32px;
    line-height: 39px;
    line-height: 100%;
    text-align: Left;
    vertical-align: Center;
    letter-spacing: 16%;
    color: #0d2f4e;
    fill: Solid;
    font-weight: 600;
  }
  .message-card {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
  }

  .message-card-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    height: auto;
    shape-outside: none;
  }

  .message-greetings {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.16em;
    text-align: left;
  }

  .avatar {
    font-size: 24px;
  }

  .profile-details-card {
    position: absolute;
    border-radius: 40px 40px 0px 0px;
    right: 0;
    left: 0;
    margin-left: 30px;
    padding-top: 4px;
  }

  .quick-access-button {
    background-color: rgb(61, 222, 214, 24%) !important;
    font-size: 11px;
    width: 48%;
    display: flex;
    justify-content: space-between;
  }

  .quick-access-icon {
    background-color: var(--v-primaryColor-base);
    width: auto;
  }

  .quick-access-text {
    font-weight: 600;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    margin-left: 8px;
  }
  .quick-access-light-text {
    color: var(--v-offWhite-base);
  }
  .quick-access-dark-text {
    color: var(--v-offBlack-base);
  }
</style>
