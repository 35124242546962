<script>
  import WeekStats from '@/components/profile/week-stats/WeekStats.vue';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import userFSDocumentMixin from '@/mixins/userFSDocumentMixin';
  import formatMinutes from '@/utils/formatMinutes.js';
  export default {
    name: 'profile',
    components: {
      WeekStats,
    },
    mixins: [userFSDocumentMixin],
    async created() {
      await Promise.all([this.fetchUser(), this.getOrganizationName(), this.getStreak(), this.getProfileStats()]);
    },
    data() {
      return {
        isGreetingVisible: true,
        isFetchingOrganization: true,
        organizationName: '',
        organizationTheme: {},
        isLoadingStreak: true,
        isLoadingProfileStats: true,
        selectedTab: 0,
        streakOfThisWeek: null,
        streakOfLastWeek: null,
        consumedTimeThisMonth: null,
        consumedTimeThisWeek: null,
        consumedTimeLastWeek: null,
        totalCoursesThisWeek: 0,
        totalCoursesLastWeek: 0,
      };
    },
    computed: {
      ...mapGetters('AuthModule', ['getCurrentUserFromFirebaseAuth', 'getUserOrganizationId']),
      monthlyConsumedTimeFormatted() {
        const { totalHours, remainingMinutes } = formatMinutes(this.consumedTimeThisMonth);
        return `${totalHours}HR ${remainingMinutes}MIN`;
      },
      streakMessageHeader() {
        switch (this.streakOfThisWeek) {
          case 0:
          case 1:
            return 'GREAT START!';
          case 2:
            return 'NICE WORK!';
          case 3:
            return "IT'S THE MAGIC NUMBER!";
          case 4:
            return 'YOU GOT THIS!';
          case 5:
            return 'LOOG AT YOU GO!';
          case 6:
            return 'NICE ONE!';
          default:
            return "YOU'RE BECOMING A PRO!";
        }
      },
      streakMessageContent() {
        switch (this.streakOfThisWeek) {
          case 0:
          case 1:
            return {
              firstPart: 'You’re on a ',
              secondPart: '. Is that technically a streak? Who cares. Starting is the hardest part and you smashed it.',
              space: false,
            };
          case 2:
            return {
              firstPart: 'You’re currently on a ',
              secondPart: 'with looking after your wellbeing. Can you keep it going? Of course you can you ledge 💪',
              space: true,
            };
          case 3:
            return {
              firstPart: "Wow! You've got a ",
              secondPart: "under your belt. I guess the number's not the only thing that's magic...! 👏",
              space: true,
            };
          case 4:
            return {
              firstPart: 'You’re currently on a ',
              secondPart: '! You should be very proud of yourself indeed, your mind will thank you! 🧠',
              space: false,
            };
          case 5:
            return {
              firstPart: "You're rocking a ",
              secondPart: 'Well done for giving your mind the TLC it deserves. Will we see you again tomorrow?',
              space: true,
            };
          case 6:
            return {
              firstPart: 'You’re currently on a ',
              secondPart:
                ". That's one day off a week of wellbeing! Keep up the good... no, great... no, AMAZING work!",
              space: false,
            };
          default:
            return {
              firstPart: "Epic! You're on a ",
              secondPart: '! Can you keep it going? Abso-blooming-loutley you wellbeing champ! 😸',
              space: false,
            };
        }
      },
    },
    methods: {
      ...mapActions('OrganizationsModule', { fetchUserOrganizaion: 'fetchOne' }),
      ...mapActions('StatsModule', ['fetchStreak', 'fetchProfileStats']),
      ...mapMutations(['setErrorSnackbarMessage']),
      async getOrganizationName() {
        this.isFetchingOrganization = true;
        try {
          const { data } = await this.fetchUserOrganizaion(this.getUserOrganizationId);
          this.organizationName = data.name;
          this.organizationTheme = data.theme;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isFetchingOrganization = false;
        }
      },
      async getStreak() {
        this.isLoadingStreak = true;
        try {
          const { data } = await this.fetchStreak();
          this.streakOfThisWeek = data.thisWeekStreak;
          this.streakOfLastWeek = data.lastWeekStreak;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoadingStreak = false;
        }
      },
      async getProfileStats() {
        this.isLoadingProfileStats = true;
        try {
          const { data } = await this.fetchProfileStats();
          this.consumedTimeThisWeek = data.thisWeekDailyTime;
          this.consumedTimeLastWeek = data.lastWeekDailyTime;
          this.totalCoursesThisWeek = data.totalCoursesThisWeek;
          this.totalCoursesLastWeek = data.totalCoursesLastWeek;
          this.consumedTimeThisMonth = data.thisMonthConsumedTime;
        } catch (error) {
          this.setErrorSnackbarMessage(error);
        } finally {
          this.isLoadingProfileStats = false;
        }
      },
      hideGreeting() {
        this.isGreetingVisible = false;
      },
      navigateToRoute(name) {
        this.$router.push({
          name,
        });
      },
    },
  };
</script>
